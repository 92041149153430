/**
 * Google Tag Manager
 */

// Prevent a double count here on the first load.
turboLoadCount = 0;

document.addEventListener('turbolinks:load', function(event) {
  // Don't push on the first load
  if (turboLoadCount > 0) {
    dataLayer.push({
      event: 'turbolinksVisit',
      url: event.data.url
    });
  }
  turboLoadCount += 1;
});

// Google Tag Manager
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WMVK2S5');
// End Google Tag Manager
